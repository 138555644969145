import React, { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const MyProfile = (props) => {
  document.title = props.title;

  const getUserValue = useStoreState((state) => state.user.userInfo);

  const toSentenceCase = (str) => {
    if (!str) return "";
    return str
      .toLowerCase()
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getPreferedEmail = () => {
    let prefEmail = "";
    if (getUserValue.hasOwnProperty("Emails")) {
      const prefEmailArr = getUserValue.Emails.filter((el) => {
        return el.IsPreferred === true;
      });
      if (prefEmailArr.length > 0) {
        prefEmail = prefEmailArr[0].EmailAddress;
      }
    }
    return prefEmail;
  };

  const [preferredAddress, setPreferredAddress] = useState({});

  const getPreferedAddress = () => {
    let prefAddress = "";
    if (getUserValue.hasOwnProperty("Addresses")) {
      const prefAddressArr = getUserValue.Addresses.filter((el) => {
        return el.IsPreferred === true;
      });
      if (prefAddressArr.length > 0) {
        prefAddress = prefAddressArr[0];
      }
    }
    return prefAddress;
  };

  useEffect(() => {
    const addressPref = getPreferedAddress();
    setPreferredAddress(addressPref);
  }, [getUserValue]);

  const renderTooltip = (props) => (
    <Tooltip id="tooltip" {...props}>
      This information must match that of your PNC points card.
    </Tooltip>
  );

  return (
    <div className="container">
      <div className="row">
        <div className="offset-md-2 col-md-8">
          <div className="pnc-profile">
            <h3 role="heading" aria-level="1">
              My Profile
            </h3>

            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="pnc-profile-wrapper">
                  <div className="row">
                    <div className="col-md-12 col-lg-4">
                      <h4>
                        Personal Information
                        <span className="tooltip-image">
                          <OverlayTrigger
                            placement="auto"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                          >
                            <a href="#" onClick={(e) => e.preventDefault()}>
                              <img src="/images/tootip.svg" alt="tooltip" />
                            </a>
                          </OverlayTrigger>
                        </span>
                      </h4>
                    </div>
                    <div className="col-md-12 col-lg-8">
                      <div className="row m-20">
                        <div className="col-md-6 col-lg-6">
                          <div>
                            <strong>Prefix:</strong>
                            <br /> {getUserValue.Prefix}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <div>
                            <strong>First Name:</strong>
                            <br />
                            {toSentenceCase(getUserValue.FirstName)}
                          </div>
                        </div>
                      </div>
                      <div className="row m-20">
                        <div className="col-md-6 col-lg-6">
                          <div>
                            <strong>Middle Initial:</strong>
                            <br /> -
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <div>
                            <strong>Last Name:</strong>
                            <br />
                            {toSentenceCase(getUserValue.LastName)}
                          </div>
                        </div>
                      </div>
                      <div className="row m-20">
                        <div className="col-md-6 col-lg-6">
                          <div>
                            <strong>Suffix:</strong>
                            <br /> {getUserValue.Suffix}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <div>
                            <strong>Phone Number:</strong>
                            <br /> -
                          </div>
                        </div>
                      </div>
                      <div className="row m-20">
                        <div className="col-md-6 col-lg-6">
                          <div>
                            <strong>Email:</strong>
                            <br />
                            {getUserValue.Emails ? getPreferedEmail() : ""}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pnc-mail-wrapper">
                  <div className="row">
                    <div className="col-md-12 col-lg-4">
                      <h4 className="mailing-add">Mailing Address</h4>
                    </div>
                    <div className="col-md-12 col-lg-8">
                      <div className="row m-20">
                        <div className="col-md-6 col-lg-6">
                          <div>
                            <strong>Country:</strong>
                            <br />
                            {preferredAddress ? preferredAddress.SourceCountryCode : "-"}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <div>
                            <strong>Street:</strong>
                            <br />
                            {preferredAddress ? toSentenceCase(preferredAddress.AddressLine1) : "-"}
                          </div>
                        </div>
                      </div>
                      <div className="row m-20">
                        <div className="col-md-6 col-lg-6">
                          <div>
                            <strong>Street 2:</strong>
                            <br />
                            {preferredAddress ? toSentenceCase(preferredAddress.AddressLine2) : "-"}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <div>
                            <strong>State:</strong>
                            <br />
                            {preferredAddress ? preferredAddress.StateCode : "-"}
                          </div>
                        </div>
                      </div>
                      <div className="row m-20">
                        <div className="col-md-6 col-lg-6">
                          <div>
                            <strong>City:</strong>
                            <br />
                            {preferredAddress ? toSentenceCase(preferredAddress.City) : "-"}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <div>
                            <strong>Zip Code:</strong>
                            <br />
                            {preferredAddress ? preferredAddress.PostalCode : "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
